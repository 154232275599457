import { GetServerSidePropsContext } from 'next';
import Router from 'next/router';

import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { destroyCookie, parseCookies } from 'nookies';

import { serverRedirect, showAlert } from '@/utils';
import { onlyNumber } from '@/utils/format';
import { createPagSeguroErrorMessage } from '@/utils/pagseguro-error';

export const makeApiClient = (
  nextContext?: GetServerSidePropsContext,
  axiosOptions?: AxiosRequestConfig,
) => {
  const client = axios.create({
    ...(axiosOptions ?? {}),
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    headers: {
      ...(axiosOptions?.headers ?? {}),
      'X-Equipment-Key': process.env.NEXT_PUBLIC_EQUIPMENT_KEY ?? '',
    },
  });

  client.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      if (process.env.NEXT_PUBLIC_API_METHOD_OVERRIDE === 'true') {
        const method = config.method?.toUpperCase();
        config.headers['X-Http-Method-Override'] = method;

        if (['PUT', 'DELETE', 'PATCH'].includes(method as string)) {
          config.method = 'POST';
        }
      }

      const { '@boraTickets:token': token } = parseCookies(nextContext);

      if (token?.trim().length > 0) {
        config.headers.authorization = `Bearer ${token}`;
      } else {
        config.headers.authorization = `Bearer ${process.env.NEXT_PUBLIC_API_BASE_TOKEN}`;
      }

      return config;
    },
    error => error,
  );

  client.interceptors.response.use(
    response => response,
    async (error: AxiosError) => {
      if (!nextContext && error?.response?.data?.error) {
        const { message } = error?.response?.data?.error;
        const newMessage = createPagSeguroErrorMessage(onlyNumber(message));
        if (
          error?.response?.config?.data?.includes('pagseguro') &&
          newMessage
        ) {
          showAlert(newMessage, 'error');
        } else {
          showAlert(message, 'error');
        }
      }

      if (error?.response?.status === 401) {
        destroyCookie(nextContext, '@boraTickets:token');

        if (nextContext) {
          serverRedirect(nextContext.res, '/', 'temporary');
        } else {
          await Router.push('/');
        }
      }

      return Promise.reject(error);
    },
  );

  return client;
};

export const api = makeApiClient();
