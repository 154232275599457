const colorLight = {
  primary: '#ED4224',
  text: '#313131',
  subtext: '#707070',
  white: '#FFFFFF',
  background: '#EDEDED',
  error: '#C62828',
  success: '#2E7D32',
  yellow: '#F9A825',
  orange: '#EF6C00',
  border: '#fce5e1',
};

export default colorLight;
