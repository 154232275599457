export const createPagSeguroErrorMessage = (error: string) => {
  let message = '';
  switch (error) {
    case '5003':
      message = 'Falha de comunicação com a instituição financeira';
      break;
    case '10000':
      message = 'Marca de cartão de crédito inválida';
      break;
    case '10001':
      message = 'Número do cartão de crédito com comprimento inválido';
      break;
    case '10002':
      message = 'Formato da data inválida';
      break;
    case '10003':
      message = 'Campo de segurança CVV inválido';
      break;
    case '10004':
      message = 'Código de verificação CVV é obrigatório';
      break;
    case '10006':
      message = 'Campo de segurança com comprimento inválido';
      break;
    case '53004':
      message = 'Quantidade inválida de itens';
      break;
    case '53005':
      message = 'É necessário informar a moeda';
      break;
    case '53006':
      message = 'Valor inválido para especificação da moeda';
      break;
    case '53007':
      message = 'Referência inválida comprimento: {0}';
      break;
    case '53008':
      message = 'URL de notificação inválida';
      break;
    case '53009':
      message = 'URL de notificação com valor inválido';
      break;
    case '53010':
      message = 'O e-mail do remetente é obrigatório';
      break;
    case '53011':
      message = 'Email do remetente com comprimento inválido';
      break;
    case '53012':
      message = 'Email do remetente está com valor inválido';
      break;
    case '53013':
      message = 'O nome do remetente é obrigatório';
      break;
    case '53014':
      message = 'Nome do remetente está com comprimento inválido';
      break;
    case '53015':
      message = 'Nome do remetente está com valor inválido';
      break;
    case '53017':
      message = 'Foi detectado algum erro nos dados do seu CPF';
      break;
    case '53018':
      message = 'O código de área do remetente é obrigatório';
      break;
    case '53019':
      message =
        'Há um conflito com o código de área informado, em relação a outros dados seus';
      break;
    case '53020':
      message = 'É necessário um telefone do remetente';
      break;
    case '53021':
      message = 'Valor inválido do telefone do remetente';
      break;
    case '53022':
      message = 'É necessário o código postal do endereço de entrega';
      break;
    case '53023':
      message = 'Código postal está com valor inválido';
      break;
    case '53024':
      message = 'O endereço de entrega é obrigatório';
      break;
    case '53025':
      message = 'Endereço de entrega rua comprimento inválido: {0}';
      break;
    case '53026':
      message = 'É necessário o número de endereço de entrega';
      break;
    case '53027':
      message = 'Número de endereço de remessa está com comprimento inválido';
      break;
    case '53028':
      message = 'No endereço de entrega há um comprimento inválido';
      break;
    case '53029':
      message = 'O endereço de entrega é obrigatório';
      break;
    case '53030':
      message =
        'Endereço de entrega está com o distrito em comprimento inválido';
      break;
    case '53031':
      message = 'É obrigatório descrever a cidade no endereço de entrega';
      break;
    case '53032':
      message =
        'O endereço de envio está com um comprimento inválido da cidade';
      break;
    case '53033':
      message = 'É necessário descrever o Estado, no endereço de remessa';
      break;
    case '53034':
      message = 'Endereço de envio está com valor inválido';
      break;
    case '53035':
      message = 'O endereço do remetente é obrigatório';
      break;
    case '53036':
      message =
        'O endereço de envio está com o país em um comprimento inválido';
      break;
    case '53037':
      message = 'O token do cartão de crédito é necessário';
      break;
    case '53038':
      message = 'A quantidade da parcela é necessária';
      break;
    case '53039':
      message = 'Quantidade inválida no valor da parcela';
      break;
    case '53040':
      message = 'O valor da parcela é obrigatório.';
      break;
    case '53041':
      message = 'Conteúdo inválido no valor da parcela';
      break;
    case '53042':
      message = 'O nome do titular do cartão de crédito é obrigatório';
      break;
    case '53043':
      message =
        'Nome do titular do cartão de crédito está com o comprimento inválido';
      break;
    case '53044':
      message =
        'O nome informado no formulário do cartão de Crédito precisa ser escrito exatamente da mesma forma que consta no seu cartão obedecendo inclusive, abreviaturas e grafia errada';
      break;
    case '53045':
      message = 'O CPF do titular do cartão de crédito é obrigatório';
      break;
    case '53046':
      message = 'O CPF do titular do cartão de crédito está com valor inválido';
      break;
    case '53047':
      message =
        'A data de nascimento do titular do cartão de crédito é necessária';
      break;
    case '53048':
      message =
        'TA data de nascimento do itular do cartão de crédito está com valor inválido';
      break;
    case '53049':
      message =
        'O código de área do titular do cartão de crédito é obrigatório';
      break;
    case '53050':
      message =
        'Código de área de suporte do cartão de crédito está com valor inválido';
      break;
    case '53051':
      message = 'O telefone do titular do cartão de crédito é obrigatório';
      break;
    case '53052':
      message =
        'O número de Telefone do titular do cartão de crédito está com valor inválido';
      break;
    case '53053':
      message = 'É necessário o código postal do endereço de cobrança';
      break;
    case '53054':
      message =
        'O código postal do endereço de cobrança está com valor inválido';
      break;
    case '53055':
      message = 'O endereço de cobrança é obrigatório';
      break;
    case '53056':
      message = 'A rua, no endereço de cobrança está com comprimento inválido';
      break;
    case '53057':
      message = 'É necessário o número no endereço de cobrança';
      break;
    case '53058':
      message = 'Número de endereço de cobrança está com comprimento inválido';
      break;
    case '53059':
      message =
        'Endereço de cobrança complementar está com comprimento inválido';
      break;
    case '53060':
      message = 'O endereço de cobrança é obrigatório';
      break;
    case '53061':
      message = 'O endereço de cobrança está com tamanho inválido';
      break;
    case '53062':
      message = 'É necessário informar a cidade no endereço de cobrança';
      break;
    case '53063':
      message =
        'O item Cidade, está com o comprimento inválido no endereço de cobrança';
      break;
    case '53064':
      message = 'O estado, no endereço de cobrança é obrigatório';
      break;
    case '53065':
      message = 'No endereço de cobrança, o Estado está no formato inválido';
      break;
    case '53066':
      message = 'O endereço de cobrança do país é obrigatório';
      break;
    case '53067':
      message =
        'No endereço de cobrança, o país está com um comprimento inválido';
      break;
    case '53068':
      message = 'O email do destinatário está com tamanho inválido';
      break;
    case '53069':
      message = 'Valor inválido do e-mail do destinatário';
      break;
    case '53070':
      message = 'A identificação do item é necessária';
      break;
    case '53071':
      message = 'O ID do ítem está inválido';
      break;
    case '53072':
      message = 'A descrição do item é necessária';
      break;
    case '53073':
      message = 'Descrição do item está com um comprimento inválido';
      break;
    case '53074':
      message = 'É necessária quantidade do item';
      break;
    case '53075':
      message = 'Quantidade do item está irregular';
      break;
    case '53076':
      message = 'Há um valor inválido na quantidade do item';
      break;
    case '53077':
      message = 'O valor do item é necessário';
      break;
    case '53078':
      message = 'O Padrão do valor do item está inválido';
      break;
    case '53079':
      message = 'Valor do item está irregular';
      break;
    case '53081':
      message =
        'O remetente está relacionado ao receptor! Esse é um erro comum que só o lojista pode cometer ao testar como compras. O erro surge quando uma compra é realizada com os mesmos dados cadastrados para receber os pagamentos da loja ou com um e-mail que é administrador da loja';
      break;
    case '53084':
      message =
        'Receptor inválido! Esse erro decorre de quando o lojista usa dados relacionados com uma loja ou um conta do PagSeguro, como e-mail principal da loja ou o e-mail de acesso à sua conta não PagSeguro';
      break;
    case '53085':
      message = 'Método de pagamento indisponível';
      break;
    case '53086':
      message = 'A quantidade total do carrinho está inválida';
      break;
    case '53087':
      message = 'Dados inválidos do cartão de crédito';
      break;
    case '53091':
      message = 'O Hash do remetente está inválido';
      break;
    case '53092':
      message = 'A Bandeira do cartão de crédito não é aceita';
      break;
    case '53095':
      message = 'Tipo de transporte está com padrão inválido';
      break;
    case '53096':
      message = 'Padrão inválido no custo de transporte';
      break;
    case '53097':
      message = 'Custo de envio irregular';
      break;
    case '53098':
      message = 'O valor total do carrinho não pode ser negativo';
      break;
    case '53099':
      message = 'Montante extra inválido';
      break;
    case '53101':
      message =
        'Valor inválido do modo de pagamento. O correto seria algo do tipo default e gateway';
      break;
    case '53102':
      message =
        'Valor inválido do método de pagamento. O correto seria algo do tipo Credicard, Boleto, etc.';
      break;
    case '53104':
      message =
        'O custo de envio foi fornecido, então o endereço de envio deve estar completo';
      break;
    case '53105':
      message =
        'As informações do remetente foram fornecidas, portanto o e-mail também deve ser informado';
      break;
    case '53106':
      message = 'O titular do cartão de crédito está incompleto';
      break;
    case '53109':
      message =
        'As informações do endereço de remessa foram fornecidas, portanto o e-mail do remetente também deve ser informado';
      break;
    case '53110':
      message = 'Banco EFT é obrigatório';
      break;
    case '53111':
      message = 'Banco EFT não é aceito';
      break;
    case '53115':
      message = 'Valor inválido da data de nascimento do remetente';
      break;
    case '53117':
      message = 'Valor inválido do cnpj do remetente';
      break;
    case '53122':
      message =
        'O domínio do email do comprador está inválido. Você deve usar algo do tipo @sandbox.pagseguro.com.br';
      break;
    case '53140':
      message =
        'Quantidade de parcelas fora do limite. O valor deve ser maior que zero';
      break;
    case '53141':
      message =
        'Não foi possível concluir seu pedido, foi identificado problema com seu cadastro no pagseguro, assim entre em contato com a pagseguro.';
      break;
    case '53142':
      message = 'O cartão de crédito está com o token inválido';
      break;
    default:
      message = '';
      break;
  }
  return message;
};
