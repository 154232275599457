import { useRouter } from 'next/router';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { IEventTicket } from '@/@types/events';
import { api } from '@/services/api';
import { INITIAL_FORM_PAYMENT } from '@/templates/checkout/payment-form/initial-form-payment';
import { showAlert } from '@/utils';

import { useAuth } from './auth';

export interface ITicketUser {
  email: string;
  firstName: string;
  lastName: string;
  whatsapp: string;
}

export interface ITicketPrincipalUser extends Omit<ITicketUser, 'whatsapp'> {
  confirmEmail: string;
}

const initialTicketPrincipalUser = {
  email: '',
  firstName: '',
  lastName: '',
  confirmEmail: '',
};

export interface IUserColumn {
  ticketId: IEventTicket['id'];
  index: number;
  column: keyof ITicketUser;
  value: string;
}

export interface IAddTicketUser {
  ticketId: IEventTicket['id'];
  index: number;
  userData: ITicketUser;
}

export interface IInstallment {
  value: number;
  valueWithoutTax: number;
  number: number;
  totalWithTax: number;
  totalWithProcessTax: number;
  totalOriginal: number;
  percent: number;
  tax: number;
}

export type PaymentMethods = 'billet' | 'credit_card' | 'pix_in' | null;

export interface ICheckoutContext {
  tickets: IEventTicket[];
  setTickets: Dispatch<SetStateAction<IEventTicket[]>>;
  ticketsUsers: Record<IEventTicket['id'], ITicketUser[]>;
  ticketPrincipalUser: ITicketPrincipalUser | null;
  installments: IInstallment[];
  selectedInstallment: IInstallment;
  loadingStorage: boolean;
  handleSelectInstallment: (installment: IInstallment) => void;
  clearTickets: () => void;
  clearTicketsUsers: () => void;
  clearTicketPrincipalUser: () => void;
  totalValueTax: number;
  totalValueInCheckout: number;
  totalValueOfTheTickets: number;
  totalValueProcessPaymentTax: number;
  totalValueInCart: number;
  addTicketToCheckout: (ticket: IEventTicket, eventId: number) => void;
  addUserColumnToTicket: (payload: IUserColumn) => void;
  addUserToTicket: (payload: IAddTicketUser) => void;
  addTicketPrincipalUser: (payload: Partial<ITicketPrincipalUser>) => void;
  removeTicketFromCheckout: (ticket: IEventTicket) => void;
  findTotalSelectedItem: (ticket: IEventTicket) => number;
  checkoutStatus: number;
  setCheckoutStatus: Dispatch<SetStateAction<number>>;
  submitFunction: () => void;
  setSubmitFunction: Dispatch<SetStateAction<() => void>>;
  formTimerMinutes: number;
  setFormTimerMinutes: Dispatch<SetStateAction<number>>;
  isConfirmButtonDisabled: boolean;
  setIsConfirmButtonDisabled: Dispatch<SetStateAction<boolean>>;
  formTimerSeconds: number;
  setFormTimerSeconds: Dispatch<SetStateAction<number>>;
  paymentMethod: PaymentMethods;
  setPaymentMethod: Dispatch<SetStateAction<PaymentMethods>>;
  checkoutFormValues: typeof INITIAL_FORM_PAYMENT;
  setCheckoutFormValues: Dispatch<SetStateAction<typeof INITIAL_FORM_PAYMENT>>;
}

export const CheckoutContext = createContext({} as ICheckoutContext);

export const CheckoutProvider = ({ children }) => {
  const { clientData } = useAuth();
  const fetchInstallmentTimeout = useRef<number>();
  const [loadingStorage, setLoadingStorage] = useState(true);

  const [installments, setInstallments] = useState<IInstallment[]>([]);
  const [selectedInstallment, setSelectedInstallment] =
    useState<IInstallment>(null);

  const [tickets, setTickets] = useState<ICheckoutContext['tickets']>([]);

  const [ticketPrincipalUser, setTicketPrincipalUser] =
    useState<ITicketPrincipalUser>(initialTicketPrincipalUser);

  const [ticketsUsers, setTicketsUsers] = useState<
    ICheckoutContext['ticketsUsers']
  >({});

  const [checkoutFormValues, setCheckoutFormValues] =
    useState<typeof INITIAL_FORM_PAYMENT>(INITIAL_FORM_PAYMENT);
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(false);

  const [checkoutStatus, setCheckoutStatus] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethods>(null);
  const router = useRouter();

  const [submitFunction, setSubmitFunction] = useState<() => void>(null);

  const [formTimerMinutes, setFormTimerMinutes] = useState<number>(null);
  const [formTimerSeconds, setFormTimerSeconds] = useState<number>(null);

  const totalValueInCheckout = useMemo(() => {
    if (paymentMethod === 'credit_card' && selectedInstallment?.totalWithTax) {
      return selectedInstallment?.totalWithTax;
    }

    return installments?.[0]?.totalOriginal ?? 0;
  }, [installments, paymentMethod, selectedInstallment]);

  const totalValueProcessPaymentTax = useMemo(() => {
    if (
      paymentMethod === 'credit_card' &&
      selectedInstallment?.totalWithProcessTax
    ) {
      return (
        Number(selectedInstallment?.totalWithProcessTax ?? 0) -
        Number(selectedInstallment?.totalOriginal ?? 0)
      );
    }

    return 0;
  }, [paymentMethod, selectedInstallment]);

  const totalValueOfTheTickets = useMemo(() => {
    return tickets.reduce((previousValue, currentValue) => {
      previousValue +=
        Number(currentValue.value) * Number(currentValue.quantity);

      return previousValue;
    }, 0);
  }, [tickets]);

  const totalValueTax = useMemo(() => {
    let totalTax = tickets.reduce((previousValue, currentValue) => {
      previousValue += Number(currentValue.tax) * Number(currentValue.quantity);

      return previousValue;
    }, 0);

    if (paymentMethod === 'credit_card' && selectedInstallment?.totalWithTax) {
      totalTax +=
        Number(selectedInstallment.totalWithTax) -
        Number(selectedInstallment.totalOriginal);
    }

    return totalTax;
  }, [paymentMethod, selectedInstallment, tickets]);

  const totalValueInCart = useMemo(() => {
    if (paymentMethod === 'credit_card' && selectedInstallment?.tax) {
      return totalValueInCheckout;
    }

    return tickets.reduce((previousValue, currentValue) => {
      previousValue +=
        Number(currentValue.valueWithTax) * Number(currentValue.quantity);

      return previousValue;
    }, 0);
  }, [paymentMethod, selectedInstallment, tickets, totalValueInCheckout]);

  const clearTickets = useCallback(() => {
    setTickets([]);
  }, []);

  const clearTicketsUsers = useCallback(() => {
    setTicketsUsers({});
  }, []);

  const clearTicketPrincipalUser = useCallback(() => {
    setTicketPrincipalUser(initialTicketPrincipalUser);
  }, []);

  const addTicketPrincipalUser = useCallback(
    (payload: Partial<ITicketPrincipalUser>) => {
      setTicketPrincipalUser(old => ({ ...old, ...payload }));
    },
    [],
  );

  const handleSelectInstallment = useCallback((installment: IInstallment) => {
    setSelectedInstallment(installment);
  }, []);

  const addTicketToCheckout = useCallback(
    (ticket: IEventTicket, eventId: number) => {
      setTickets(oldTickets => {
        const cloneTickets = oldTickets.filter(
          event => event.eventId === eventId,
        );
        const index = cloneTickets.findIndex(t => t.id === ticket.id);

        if (window.fbq) {
          window.fbq('track', 'AddToCart');
        }

        if (index === -1) {
          setTicketsUsers(old => ({
            ...old,
            [ticket.id]: [
              {
                email: '',
                firstName: '',
                lastName: '',
                whatsapp: '',
              },
            ],
          }));

          const quantity =
            ticket.CASADINHA_ITEM === 'SIM'
              ? parseInt(ticket.FATOR_CASADINHA_ITEM, 10)
              : 1;

          return [
            ...cloneTickets,
            {
              ...ticket,
              quantity,
            },
          ];
        }

        const quantity =
          ticket.CASADINHA_ITEM === 'SIM'
            ? parseInt(ticket.FATOR_CASADINHA_ITEM, 10)
            : 1;
        const newQuantity = cloneTickets[index].quantity + quantity;

        if (ticket.passport === 'SIM') {
          // forca passaporte apenas 2 item
          ticket.availableByClient = 2;
        }

        if (
          newQuantity > ticket.availableByClient ||
          newQuantity > ticket.available
        ) {
          let availableTickets = ticket.availableByClient;

          if (ticket.availableByClient > ticket.available) {
            availableTickets = ticket.available;
          }

          showAlert(
            `${ticket.name} tem limite de ${availableTickets} por cliente.`,
            'error',
          );

          return cloneTickets;
        }

        cloneTickets[index].quantity = newQuantity;

        setTicketsUsers(old => ({
          ...old,
          [ticket.id]: Array(newQuantity).fill({
            email: '',
            firstName: '',
            lastName: '',
            whatsapp: '',
          }),
        }));

        return cloneTickets;
      });
    },
    [],
  );

  const removeTicketFromCheckout = useCallback((ticket: IEventTicket) => {
    setTickets(oldTickets => {
      const index = oldTickets.findIndex(t => t.id === ticket.id);

      if (index === -1) {
        return oldTickets;
      }

      const quantity =
        ticket.CASADINHA_ITEM === 'SIM'
          ? parseInt(ticket.FATOR_CASADINHA_ITEM, 10)
          : 1;

      oldTickets[index].quantity -= quantity;

      if (oldTickets[index].quantity <= 0) {
        return oldTickets.filter(t => t.id !== ticket.id);
      }

      return [...oldTickets];
    });
  }, []);

  const findTotalSelectedItem = useCallback(
    (ticket: IEventTicket) => {
      const findTicket = tickets.find(t => t.id === ticket.id);

      return findTicket?.quantity ?? 0;
    },
    [tickets],
  );

  const addUserColumnToTicket = useCallback(
    ({ ticketId, index, column, value }: IUserColumn) => {
      setTicketsUsers(oldTicketUsers => {
        if (!oldTicketUsers.hasOwnProperty(ticketId)) {
          oldTicketUsers[ticketId] = [];
        }

        const ticketsById = [...oldTicketUsers[ticketId]];

        ticketsById[index] = {
          ...ticketsById[index],
          [column]: value,
        };

        return {
          ...oldTicketUsers,
          [ticketId]: ticketsById,
        };
      });
    },
    [],
  );

  const addUserToTicket = useCallback(
    ({ ticketId, index, userData }: IAddTicketUser) => {
      setTicketsUsers(oldTicketUsers => {
        if (!oldTicketUsers.hasOwnProperty(ticketId)) {
          oldTicketUsers[ticketId] = [];
        }

        const ticketsById = [...oldTicketUsers[ticketId]];

        ticketsById[index] = {
          ...ticketsById[index],
          ...userData,
        };

        return {
          ...oldTicketUsers,
          [ticketId]: ticketsById,
        };
      });
    },
    [],
  );

  // clear all checkout
  if (checkoutStatus !== 0 && !router.pathname.includes('checkout')) {
    setPaymentMethod(null);
    setCheckoutStatus(0);
    setCheckoutFormValues({
      holder: {
        name: '',
        email: '',
        phone: '',
        birthDate: '',
        document: {
          type: 'CPF',
          value: '',
        },
      },
      creditCard: {
        number: '',
        expirationMonth: '',
        expirationYear: '',
        cvv: '',
        installments: '1',
        brand: '',
      },
      address: {
        street: '',
        number: '',
        complement: '',
        district: '',
        city: '',
        state: '',
        zipCode: '',
      },
      visitor: {
        visitor_id: '',
        card_bin: '',
        card_last4: '',
      },
    });
    setInstallments([]);
    setSelectedInstallment(null);
    setSubmitFunction(null);
    setFormTimerMinutes(null);
    setFormTimerSeconds(null);
    clearTickets();
    clearTicketsUsers();
    clearTicketPrincipalUser();
    setIsConfirmButtonDisabled(false);
    // clearAllTicketsOnLocalStorage();
  }

  useEffect(() => {
    clearTimeout(fetchInstallmentTimeout.current);

    if (tickets.length <= 0) {
      setInstallments([]);

      return;
    }

    fetchInstallmentTimeout.current = window.setTimeout(() => {
      (async () => {
        const response = await api.post(
          '/v1/shared/payment/installments',
          tickets.map(row => ({
            id: row.id,
            quantity: row.quantity,
          })),
        );

        setInstallments(response.data);
      })();
    }, 500);

    return () => {
      clearTimeout(fetchInstallmentTimeout.current);
    };
  }, [fetchInstallmentTimeout, tickets]);

  useEffect(() => {
    const storageTickets =
      window.localStorage.getItem('@bcart.tickets') ?? false;

    setTickets(storageTickets ? JSON.parse(storageTickets) : []);

    const storageTicketsUsers =
      window.localStorage.getItem('@bcart.ticketsUsers') ?? false;

    setTicketsUsers(storageTicketsUsers ? JSON.parse(storageTicketsUsers) : {});

    const storageTicketPrincipalUser =
      window.localStorage.getItem('@bcart.ticketPrincipalUser') ?? false;

    setTicketPrincipalUser(
      storageTicketPrincipalUser ? JSON.parse(storageTicketPrincipalUser) : {},
    );

    setLoadingStorage(false);
  }, []);

  useEffect(() => {
    window.localStorage.setItem('@bcart.tickets', JSON.stringify(tickets));

    window.localStorage.setItem(
      '@bcart.ticketsUsers',
      JSON.stringify(ticketsUsers),
    );

    window.localStorage.setItem(
      '@bcart.ticketPrincipalUser',
      JSON.stringify(ticketPrincipalUser),
    );
  }, [ticketPrincipalUser, tickets, ticketsUsers]);

  useEffect(() => {
    if (
      !Object.keys(ticketPrincipalUser ?? {}).length &&
      clientData?.codCliente
    ) {
      const [firstName, lastName] = clientData.nomeCliente.split(' ', 2);

      setTicketPrincipalUser({
        email: clientData.emailCliente,
        confirmEmail: '',
        firstName,
        lastName,
      });
    }
  }, [clientData, ticketPrincipalUser]);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (formTimerSeconds > 0) {
        setFormTimerSeconds(formTimerSeconds - 1);
      }

      if (formTimerSeconds === 0) {
        if (formTimerMinutes === 0) {
          clearInterval(myInterval);
        } else {
          setFormTimerMinutes(formTimerMinutes - 1);
          setFormTimerSeconds(59);
        }
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if (router.asPath.match(/\/(checkout|items-widget)\/.+\/\d/gi)) {
      return;
    }

    setPaymentMethod(null);
    setCheckoutStatus(1);
    setCheckoutFormValues(INITIAL_FORM_PAYMENT);
    setInstallments([]);
    setSelectedInstallment(null);
    setSubmitFunction(null);
    setFormTimerMinutes(null);
    setFormTimerSeconds(null);
    setTicketPrincipalUser(initialTicketPrincipalUser);
    setTicketsUsers({});
    setTickets([]);
  }, [router.asPath]);

  const providerValue = useMemo<ICheckoutContext>(
    () => ({
      tickets,
      setTickets,
      ticketsUsers,
      ticketPrincipalUser,
      totalValueInCheckout,
      totalValueProcessPaymentTax,
      totalValueInCart,
      addTicketToCheckout,
      addUserColumnToTicket,
      removeTicketFromCheckout,
      clearTicketPrincipalUser,
      findTotalSelectedItem,
      clearTickets,
      clearTicketsUsers,
      addTicketPrincipalUser,
      installments,
      selectedInstallment,
      handleSelectInstallment,
      loadingStorage,
      checkoutStatus,
      setCheckoutStatus,
      submitFunction,
      setSubmitFunction,
      formTimerMinutes,
      setFormTimerMinutes,
      formTimerSeconds,
      setFormTimerSeconds,
      isConfirmButtonDisabled,
      setIsConfirmButtonDisabled,
      paymentMethod,
      setPaymentMethod,
      checkoutFormValues,
      setCheckoutFormValues,
      totalValueTax,
      totalValueOfTheTickets,
      addUserToTicket,
    }),
    [
      tickets,
      ticketsUsers,
      ticketPrincipalUser,
      totalValueInCheckout,
      totalValueProcessPaymentTax,
      totalValueInCart,
      addTicketToCheckout,
      addUserColumnToTicket,
      removeTicketFromCheckout,
      clearTicketPrincipalUser,
      findTotalSelectedItem,
      clearTickets,
      clearTicketsUsers,
      addTicketPrincipalUser,
      installments,
      selectedInstallment,
      handleSelectInstallment,
      loadingStorage,
      checkoutStatus,
      submitFunction,
      formTimerMinutes,
      formTimerSeconds,
      isConfirmButtonDisabled,
      paymentMethod,
      checkoutFormValues,
      totalValueTax,
      totalValueOfTheTickets,
      addUserToTicket,
    ],
  );

  return (
    <CheckoutContext.Provider value={providerValue}>
      {children}
    </CheckoutContext.Provider>
  );
};

export const useCheckout = () => useContext(CheckoutContext);
