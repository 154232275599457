export const INITIAL_FORM_PAYMENT = {
  holder: {
    name: '',
    email: '',
    phone: '',
    birthDate: '',
    document: {
      type: 'CPF',
      value: '',
    },
  },
  creditCard: {
    number: '',
    expirationMonth: '',
    expirationYear: '',
    cvv: '',
    installments: '1',
    brand: '',
  },
  address: {
    street: '',
    number: '',
    complement: '',
    district: '',
    city: '',
    state: '',
    zipCode: '',
  },
  visitor: {
    visitor_id: '',
    card_bin: '',
    card_last4: '',
  },
};
