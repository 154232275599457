const configCommon = {
  seoImage: '/images/seo.jpg',

  url: (path?: string): string => {
    const urlByEnvironment =
      process.env.NODE_ENV === 'production'
        ? 'https://vendas.boratickets.com.br'
        : 'http://localhost:3000';

    return `${urlByEnvironment}${path ?? ''}`;
  },
};

export default configCommon;
