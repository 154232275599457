import Router from 'next/router';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { destroyCookie, parseCookies, setCookie } from 'nookies';

import {
  ClientFormRecoverPassword,
  ClientFormLogin,
  ClientFormRegister,
  ClientLoginResponse,
  IClient,
} from '@/@types/client';
import { api } from '@/services/api';
import { clearAllTicketsOnLocalStorage, showAlert } from '@/utils';

export interface IAuthContext {
  clientData?: IClient | null;
  setClientData: Dispatch<SetStateAction<IClient>>;
  isAuthenticated: boolean;
  shouldInformEmail: boolean;
  handleLogin: (payload: ClientFormLogin) => Promise<void>;
  handleRegister: (payload: ClientFormRegister) => Promise<void>;
  handleRecoverPassword: (payload: ClientFormRecoverPassword) => Promise<void>;
  handleLogout: () => void;
}

export const AuthContext = createContext({} as IAuthContext);

export const AuthProvider = ({ children }) => {
  const [client, setClient] = useState<IAuthContext['clientData']>(null);
  const [shouldInformEmail, setShouldInformEmail] = useState(false);

  const registerCookie = useCallback((token: string) => {
    setCookie(null, '@boraTickets:token', token, {
      path: '/',
      maxAge: 60 * 60 * 4, // 4 hours
    });
  }, []);

  const handleLogin = useCallback(
    async (payload: ClientFormLogin) => {
      try {
        const response = await api.post<ClientLoginResponse>(
          '/v1/web/auth/login',
          payload,
        );

        registerCookie(response.data.token);
        setClient(response.data.client);
      } catch (err) {
        if (err) {
          const message = err?.response?.data?.error?.message;
          if (message) {
            return console.log(message);
          }
          return console.log('Error without message');
        }

        return console.log('Undentified error');
      }
    },
    [registerCookie],
  );

  const handleRegister = useCallback(
    async (payload: ClientFormRegister) => {
      const response = await api.post<ClientLoginResponse>(
        '/v1/web/auth/register',
        payload,
      );

      registerCookie(response.data.token);
      setClient(response.data.client);
    },
    [registerCookie],
  );

  const handleRecoverPassword = useCallback(
    async (payload: ClientFormRecoverPassword) => {
      const path = '/v1/web/auth/forgot-password';
      if (!shouldInformEmail) {
        try {
          await api.post(path, {
            document: payload.CPF_CLIENTE,
          });
          showAlert('Email enviado com sucesso', 'success');
        } catch (err) {
          const { response } = err;
          if (response.status === 406) {
            setShouldInformEmail(true);
            showAlert('Por favor coloque o seu email', 'info');
            throw err;
          } else {
            throw err;
          }
        }
      } else {
        try {
          await api.post(path, {
            document: payload.CPF_CLIENTE,
            email: payload.EMAIL_CLIENTE,
          });
          showAlert('Email enviado com sucesso', 'success');
        } catch (err) {
          const { response } = err;
          showAlert(response.data.error.message, 'error');
        }
      }
    },
    [shouldInformEmail],
  );

  const handleLogout = useCallback(async () => {
    setClient(null);
    destroyCookie(null, '@boraTickets:token');

    clearAllTicketsOnLocalStorage();
    await Router.push('/painel/sair');
  }, []);

  const providerValue = useMemo(
    () => ({
      handleLogin,
      handleRegister,
      handleRecoverPassword,
      handleLogout,
      shouldInformEmail,
      isAuthenticated: !!client,
      clientData: client,
      setClientData: setClient,
    }),
    [
      client,
      handleLogin,
      handleLogout,
      handleRecoverPassword,
      handleRegister,
      shouldInformEmail,
    ],
  );

  useEffect(() => {
    (async () => {
      try {
        const { '@boraTickets:token': cookieToken } = parseCookies();

        if (cookieToken) {
          registerCookie(cookieToken);
          const response = await api.get<IClient>('/v1/web/profile');
          setClient(response.data);
        }
      } catch {
        setClient(null);
      }
    })();
  }, [registerCookie]);

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
