import { AppProps } from 'next/app';
import Head from 'next/head';
import React, { StrictMode } from 'react';
import { ToastContainer } from 'react-toastify';

import NProgress from '@/components/NProgress';
import SEO from '@/components/SEO';
import configCommon from '@/config';
import { AuthProvider } from '@/contexts/auth';
import { CheckoutProvider } from '@/contexts/checkout';
import { ThemeProvider } from '@/contexts/theme';
import theme from '@/styles/theme';

const App: React.FC<AppProps> = ({ Component, pageProps }) => (
  <>
    <StrictMode>
      <AuthProvider>
        <CheckoutProvider>
          <ThemeProvider>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />

              <link rel="base" href={configCommon.url()} />
              {theme.color.primary && (
                <>
                  <meta name="theme-color" content={theme.color.primary} />
                  <meta
                    name="msapplication-TileColor"
                    content={theme.color.primary}
                  />
                </>
              )}
              <link rel="manifest" href="/manifest.json" />
              <link rel="shortcut icon" href="/favicon.jpg" type="image/jpg" />
              <meta property="og:site_name" content="Bora Tickets" />
              <meta property="og:type" content="website" />
              <meta property="og:locale" content="pt_BR" />
            </Head>

            <ToastContainer />
            <SEO />
            <NProgress />
            <Component {...pageProps} />
          </ThemeProvider>
        </CheckoutProvider>
      </AuthProvider>
    </StrictMode>
  </>
);

export default App;
